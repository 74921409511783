import { template as template_ed4dc9da8c4b40919498543f0c24aa26 } from "@ember/template-compiler";
const FKLabel = template_ed4dc9da8c4b40919498543f0c24aa26(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
