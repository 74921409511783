import { template as template_ae0d65a6d1224c24b757b8b91906b23e } from "@ember/template-compiler";
const FKControlMenuContainer = template_ae0d65a6d1224c24b757b8b91906b23e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
