import { template as template_7a49a8f95f2643ecab0b6120d9bb1903 } from "@ember/template-compiler";
const WelcomeHeader = template_7a49a8f95f2643ecab0b6120d9bb1903(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
