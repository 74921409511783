import { template as template_5a1ba5686eda417a8f3291ea0a74b8ba } from "@ember/template-compiler";
const FKText = template_5a1ba5686eda417a8f3291ea0a74b8ba(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
